import React, { ReactElement, useContext } from "react";
import { Redirect } from "react-router-dom";
import Amplify from "aws-amplify";

type PropsType = {
  children: React.ReactNode;
};

import { UserContext } from "../../contexts/UserContext";

export const AdminAuth = ({ children }: PropsType): ReactElement<any, any> => {
  const { isAdmin, userReady } = useContext(UserContext);

  Amplify.configure({
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  });

  return (
    <>
      {userReady && <>{isAdmin ? <>{children}</> : <Redirect to="/error" />}</>}
    </>
  );
};

export default AdminAuth;
