import React, { useState, useEffect } from "react";
//import Amplify, { API, Storage } from "aws-amplify";
import Amplify, { API } from "aws-amplify";

import awsconfig from "../../aws-exports";
Amplify.configure(awsconfig);

import { getPost } from "../../graphql/queries";
import { updatePost as updatePostMutation } from "../../graphql/mutations";

import { postIF } from "../../config/interface";

import css from "./RecipeItem.module.scss";

export interface Props {
  post: postIF;
}

const RecipeItem: React.FC<Props> = ({ post }) => {
  //console.log(post);

  const [formData, setFormData] = useState<postIF>(post);
  //const [image, setImage] = useState<any | null>(null);
  const [ready, setReady] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    if (!post.id) return;

    setReady(true);
    //getS3Image();
  }, []);

  /* 画像取得 */
  // async function getS3Image() {
  //   if (!post.image) return;

  //   const image = await Storage.get(post.image);

  //   if (image) {
  //     setImage(image);
  //   }
  // }

  /* 情報更新 */
  async function updateForm() {
    //console.log("updateForm", formData);

    const postData = {
      id: formData.id,
      title: formData.title,
      description: formData.description,
      recipe: formData.recipe,
      foodstuff: formData.foodstuff,
      date: formData.date,
      status: formData.status,
      _version: formData._version,
    };

    //console.log("postData", postData);

    await API.graphql({
      query: updatePostMutation,
      variables: { input: postData },
    });

    const apiData: any = await API.graphql({
      query: getPost,
      variables: {
        id: formData.id,
      },
    });
    //console.log(apiData.data.getPost);

    setFormData(apiData.data.getPost);

    setEditMode(false);
  }

  /* 編集モード切り替え */
  const editModeToggle = () => {
    //console.log("editModeOpen");

    setEditMode(!editMode);
  };

  const label = () => {
    if (formData.status == "DRAFT") {
      return css.status_draft;
    } else if (formData.status == "OPEN") {
      return css.status_open;
    } else if (formData.status == "CLOSE") {
      return css.status_close;
    }
  };

  return (
    <>
      {ready && (
        <div key={post.id || post.title} className={css.wrapper}>
          {!editMode ? (
            <div className={css.mode_button} onClick={editModeToggle}>
              <i className="fal fa-edit"></i>
            </div>
          ) : (
            <div className={css.mode_button} onClick={editModeToggle}>
              <i className="fal fa-times-circle"></i>
            </div>
          )}

          <div className={css.image}>


                <img
                  src={IMAGE_URL + post.image}
                  className={css.post_image}
                />
                <br />
                <img
                  src={IMAGE_URL + "thumb/" + post.image}
                  className={css.post_image_thumb}
                />
          </div>
          <div className={css.info_container}>
            {!editMode ? (
              <>
                <div className={css.title}>{formData.title}</div>
                <div className={css.item}>
                  <span className={css.single_label}>作成日</span>
                  {formData.date}
                </div>
                <div className={css.item}>
                  <span className={css.single_label}>公開ステータス</span>
                  <span className={label()}>{formData.status}</span>
                </div>
                <div className={css.item}>
                  <div>概要</div>
                  <div className={css.box}>{formData.description}</div>
                </div>
                <div className={css.item}>
                  <div>レシピ</div>
                  <div className={css.box}>{formData.recipe}</div>
                </div>
                <div className={css.item}>
                  <div>食材</div>
                  <div className={css.box}>{formData.foodstuff}</div>
                </div>
                <div className={css.sub_text}>ID:{formData.id}</div>
                <div className={css.sub_text}>User:{formData.userId}</div>
                <div className={css.sub_text}>Created:{formData.createdAt}</div>
                <div className={css.sub_text}>Updated:{formData.updatedAt}</div>
              </>
            ) : (
              <>
                <form className={css.form_container}>
                  <div className={css.item}>
                    <div className={css.label}>タイトル</div>
                    <input
                      onChange={(e) =>
                        setFormData({ ...formData, title: e.target.value })
                      }
                      defaultValue={formData.title}
                      className={css.input_text}
                    />
                  </div>
                  <div className={css.item}>
                    <div className={css.label}>概要</div>
                    <textarea
                      className={css.text_area}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          description: e.target.value,
                        })
                      }
                    >
                      {formData.description}
                    </textarea>
                  </div>
                  <div className={css.item}>
                    <div className={css.label}>レシピ</div>
                    <textarea
                      className={css.text_area}
                      onChange={(e) =>
                        setFormData({ ...formData, recipe: e.target.value })
                      }
                    >
                      {formData.recipe}
                    </textarea>
                  </div>
                  <div className={css.item}>
                    <div className={css.label}>食材</div>
                    <textarea
                      className={css.text_area}
                      onChange={(e) =>
                        setFormData({ ...formData, foodstuff: e.target.value })
                      }
                    >
                      {post.foodstuff}
                    </textarea>
                  </div>
                  <div className={css.item}>
                    <div className={css.label}>作成日</div>
                    <input
                      onChange={(e) =>
                        setFormData({ ...formData, date: e.target.value })
                      }
                      defaultValue={formData.date}
                      className={css.input_text_s}
                    />
                  </div>
                  <div className={css.item}>
                    <div className={css.label}>公開ステータス</div>
                    <select
                      className={css.select_box}
                      value={formData.status}
                      onChange={(e) =>
                        setFormData({ ...formData, status: e.target.value })
                      }
                    >
                      <option value="DRAFT">DRAFT</option>
                      <option value="OPEN">OPEN</option>
                      <option value="CLOSE">CLOSE</option>
                    </select>
                  </div>

                  <div className={css.update_button} onClick={updateForm}>
                    更新
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default RecipeItem;
