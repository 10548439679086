import React, { useState, useEffect } from "react";

import { API } from "aws-amplify";

import SupporToolHelper from "../_SupporToolHelper";

import { listStatusFromPostByDate } from "../../graphql/queries";
import { postIF } from "../../config/interface";

import { date } from "../../util/dateUtil";

import css from "./SitemapSupport.module.scss";

const BASE_URL = "https://remoteworklun.ch/recipe/";

export const SitemapSupport: React.FC = () => {
  const [ready, setReady] = useState<boolean>(false);
  const [posts, setPosts] = useState<postIF[]>([]);
  const [progress, setProgress] = useState<string>("");

  useEffect(() => {
    fetchList();
  }, []);

  /* リスト取得 */
  async function fetchList() {
    //console.log("fetchList", more);

    let nextToken = null;

    const tmp: postIF[] = [];

    let progressText = "";

    for (let i = 0; i < 999; i++) {
      //   console.log(i, "nextToken", nextToken);

      progressText += ".";

      setProgress(progressText);

      const apiData: any = await API.graphql({
        query: listStatusFromPostByDate,
        variables: {
          status: "OPEN",
          sortDirection: "DESC",
          nextToken,
        },
      });
      console.log(apiData);

      const items = apiData.data.listStatusFromPostByDate.items;
      console.log(items);

      for (const item of items) {
        //console.log(item);

        if (item._deleted == true) {
          continue;
        }

        //console.log(item.id);

        item.date = date("Y-m-dTH:i:s+00:00", item.updatedAt);

        tmp.push(item);
      }

      if (apiData.data.listStatusFromPostByDate.nextToken) {
        nextToken = apiData.data.listStatusFromPostByDate.nextToken;
      } else {
        nextToken = null;
      }

      if (nextToken == null) {
        break;
      }
    }

    console.log(tmp);

    setPosts(tmp);

    setReady(true);
  }

  return (
    <SupporToolHelper>
      <div className={css.wrapper}>
        <div className={css.title}>SitemapSupport</div>

        <div>
          {ready ? (
            <div className={css.sitemap_container}>
              {posts.map((post) => (
                <div key={post.id}>
                  &lt;url&gt;
                  <br />
                  &nbsp;&nbsp;&lt;loc&gt;{BASE_URL}
                  {post.id}&lt;/loc&gt;
                  <br />
                  &nbsp;&nbsp;&lt;lastmod&gt;{post.date}&lt;/lastmod&gt;
                  <br />
                  &nbsp;&nbsp;&lt;priority&gt;0.80&lt;/priority&gt;
                  <br />
                  &lt;/url&gt;
                  <br />
                </div>
              ))}
            </div>
          ) : (
            <div className={css.loading}>
              <i className={"fas fa-spinner " + css.spinner_icon}></i>Creating a
              site map&nbsp;{progress}
            </div>
          )}
        </div>
      </div>
    </SupporToolHelper>
  );
};

export default SitemapSupport;
