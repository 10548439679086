import React from 'react';

import "./Teaser.scss";

export const Teaser: React.FC = () => {

  return (
    <div className="App">
      <header className="App-header">
        <img src="/remomeshi_975x215.png" className="App-logo" alt="logo" />
        <p style={{fontSize:30}}>
          COMING SOON...
        </p>
      </header>
    </div>
  );
}

export default Teaser;
