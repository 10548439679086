import React, { useEffect } from "react";

import css from "./Adsense.module.scss";

export default function Adsense() {
  const env = process.env.REACT_APP_ENV;

  const active = process.env.REACT_APP_GOOGLE_AD;

  useEffect(() => {
    //console.log(env);

    if (window.adsbygoogle && env === "prod" && active == "ACTIVE") {
      window.adsbygoogle.push({});
    }
  }, []);

  return (
    <>
      {active == "ACTIVE" && (
        <div className={css.wrapper}>
          {env == "prod" ? (
            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client={process.env.REACT_APP_GOOGLE_AD_CLIENT}
              data-ad-slot={process.env.REACT_APP_GOOGLE_AD_SLOT}
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          ) : (
            <div className={css.dummy}>AD</div>
          )}
        </div>
      )}
    </>
  );
}
