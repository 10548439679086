import React, { useState, useEffect, useContext, useRef } from "react";
import Amplify, { API, Storage } from "aws-amplify";

import { listFromPostByDate } from "../graphql/queries";
import { createPost as createPostMutation } from "../graphql/mutations";

import { date } from "../util/dateUtil";

import AdminAuth from "./components/AdminAuth";
import ImagePreview from "./components/ImagePreview";
import RecipeItem from "./components/RecipeItem";

import { UserContext } from "../contexts/UserContext";
import { LoadingContext } from "../contexts/LoadingContext";

import css from "./AdminImgUploader.module.scss";

import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);

import { postIF } from "../config/interface";

export interface postFieldIF {
  title: string;
  image: string;
  status: string;
  sort: string;
  date: string;
  userId: string;
}

const fetchItems = 10;
const minItems = 10;

export const AdminImgUploader: React.FC = () => {
  const [posts, setPosts] = useState<postIF[]>([]);
  const [files, setFiles] = useState<File[] | null>(null);
  const [formImageReady, setFormImageReady] = useState<boolean>(false);
  const [nextTokenString, setNextTokenString] = useState<string | null>(null);
  const [moreProcess, setMoreProcess] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgres] = useState<number>(0);
  const [completeMessage, setCompleteMessage] = useState<string>("");

  const { userId } = useContext(UserContext);
  const { loading } = useContext(LoadingContext);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fetchList();
  }, []);

  /* リスト取得 */
  async function fetchList(more = false) {
    //console.log("fetchList", more);

    let nextToken = null;
    if (more == true) {
      nextToken = nextTokenString;
    }

    //console.log("nextToken", nextToken);

    const tmp: postIF[] = [];

    for (let i = 0; i < 3; i++) {
      //console.log(i, "nextToken", nextToken);

      const apiData: any = await API.graphql({
        query: listFromPostByDate,
        variables: {
          limit: fetchItems,
          sort: "TARGET",
          sortDirection: "DESC",
          nextToken,
        },
      });
      //console.log(apiData);

      const items = apiData.data.listFromPostByDate.items;
      //console.log(items);

      for (const item of items) {
        //console.log(item);

        if (item._deleted == true) {
          continue;
        }

        //console.log(item.id);

        tmp.push(item);
      }

      //console.log(apiData.data.listSamples.nextToken);

      if (apiData.data.listFromPostByDate.nextToken) {
        nextToken = apiData.data.listFromPostByDate.nextToken;
      } else {
        nextToken = null;
      }

      //console.log("tmp.length", tmp.length);

      if (tmp.length >= minItems) {
        break;
      } else if (nextToken == null) {
        break;
      }
    }

    setNextTokenString(nextToken);

    let listTmp: postIF[] = [];

    if (more == true) {
      listTmp = posts;
    }

    listTmp = listTmp.concat(tmp);

    setPosts(listTmp);

    setMoreProcess(false);
    loading(false);
  }

  // ドラフト版で画像を投稿
  async function createPost() {
    if (!files) return;
    if (!formImageReady) return;

    setUploading(true);

    setFormImageReady(false);

    let progress = 0;

    for (const file of files) {
      //console.log(file);

      progress++;
      setUploadProgres(progress);

      let fileName = date("YmdHis") + "_" + file.name;
      fileName = fileName.replace(/\s/g, "_");
      //console.log("fileName", fileName);

      const newData: postFieldIF = {
        title: fileName,
        image: fileName,
        date: date("Y-m-d"),
        userId: userId,
        status: "DRAFT",
        sort: "TARGET",
      };

      //console.log(newData);

      await API.graphql({
        query: createPostMutation,
        variables: { input: newData },
      });

      if (file) {
        // 画像アップロード;
        await Storage.put(fileName, file, {
          contentType: file.type,
        });
      }

    }

    const itemCount = files.length;

    // メッセージ表示
    setCompleteMessage(
      "アップロード完了 [ " + itemCount + " / " + itemCount + "]"
    );

    // メッセージ削除
    setTimeout(() => {
      setCompleteMessage("");
    }, 2000);

    // リセット
    setFiles(null);

    setUploading(false);

    fetchList();
  }

  // 画像選択ウィンドウを開く
  const fileSelect = () => {
    //console.log(inputRef.current);

    if (inputRef.current != null) {
      inputRef.current.click();
    }
  };

  // 画像情報保持
  async function onChange(e: any) {
    //console.log("onChange", e);

    if (!e.target.files[0]) {
      setFormImageReady(false);
      return;
    }

    setFormImageReady(true);

    const filesData: File[] = [];
    //console.log(filesData);

    const max = 20;

    for (const index in e.target.files) {
      //console.log(index);

      if (index == "length" || index == "item") {
        continue;
      }

      if (parseInt(index) >= max) {
        continue;
      }

      const tmp = e.target.files[index];
      //sconsole.log(tmp);

      filesData.push(tmp);
    }

    //console.log(filesData);

    setFiles(filesData);
  }

  // 追加読み込み
  async function moreFetch() {
    setMoreProcess(true);
    fetchList(true);
  }

  return (
    <AdminAuth>
      <div className={css.wrapper}>
        <div className={css.header_title}>Upload Post Images</div>
        <div className={css.form}>
          {files && (
            <div className={css.image_previews}>
              {files.map((file, index) => (
                <React.Fragment key={index}>
                  <ImagePreview file={file} cName={css.preview} />
                </React.Fragment>
              ))}
            </div>
          )}
          <div className={css.form_items}>
            <input
              hidden
              ref={inputRef}
              type="file"
              multiple
              onChange={onChange}
            />
            {uploading ? (
              <div className={css.uploading}>
                <i className={"fas fa-spinner " + css.spinner_icon}></i>
                画像をアップロードしています&nbsp;[{uploadProgress}&nbsp;/&nbsp;
                {files?.length}]
              </div>
            ) : (
              <>
                {completeMessage ? (
                  <div className={css.complete_message}>{completeMessage}</div>
                ) : (
                  <>
                    <div
                      onClick={fileSelect}
                      className={css.file_select_button}
                    >
                      画像を選択
                    </div>
                    {formImageReady ? (
                      <div className={css.submit_button} onClick={createPost}>
                        登録
                      </div>
                    ) : (
                      <div className={css.submit_button_disable}>登録</div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <div className={css.posts_container}>
          {posts.map((post) => (
            <React.Fragment key={post.id}>
              <RecipeItem post={post} />
            </React.Fragment>
          ))}
          {nextTokenString && (
            <div className={css.more_button_container}>
              {!moreProcess && (
                <span className={css.more_button} onClick={moreFetch}>
                  more
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </AdminAuth>
  );
};

export default AdminImgUploader;
