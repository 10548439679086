import React, { ReactElement, useEffect } from "react";
import { Link } from "react-router-dom";

import { AmplifySignOut } from "@aws-amplify/ui-react";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";

import ModalDialog from "../../components/ModalDialog";

import css from "./SignOut.module.scss";

const SignOut: React.FC = (): ReactElement<any, any> => {
  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData: any) => {
      console.log("onAuthUIStateChange", nextAuthState, authData);

      if (nextAuthState === "signedout") {
        location.href = "/";
      }
    });
  }, []);

  return (
      <ModalDialog>
        <div className={css.wrapper}>
          <div>サインアウト</div>
          <div className={css.flex_container}>
            <AmplifySignOut buttonText="サインアウト" />
            <Link to="/" className={css.cancel_button}>
              <span>キャンセル</span>
            </Link>
          </div>
        </div>
      </ModalDialog>
  );
};

export default SignOut;
