import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./css/common.scss";
import "./App.scss";

import UserMgr from "./manager/UserMgr";

import Home from "./pages/Home";
import Recipe from "./pages/Recipe";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Error from "./pages/Error";

import About from "./pages/About";
import Company from "./pages/Company";
import Terms from "./pages/Terms";
import News from "./pages/News";

import Admin from "./admin/Admin";
import AdminImgUp from "./admin/AdminImgUploader";
import AdminSignIn from "./pages/userAuth/SignIn";
import AdminSignOut from "./pages/userAuth/SignOut";

import SupportToolBar from "./_devSupportTool/SupportToolBar";
import SupportTool from "./_devSupportTool/SupportTool";

import GoogleAnalitics from "./pages/components/GoogleAnalitics";

import { ADMIN_BASE_PATH as ABP } from "./config/config";
import { DEVTOOL_BASE_PATH as DTBP } from "./config/config";

import { UserContext } from "./contexts/UserContext";

export const App: React.FC = () => {

  const { userReady } = useContext(UserContext);

  return (
    <Router>
      <UserMgr />
      {userReady && (
        <div className="App-conteiner">
          <div className="App-conteiner-inner">
            <Header />
            <Switch>
              <Route path="/about" exact component={About} />
              <Route path="/" exact component={Home} />
              <Route path="/recipe/:postId" exact component={Recipe} />
              <Route path="/recipe" exact>
                <Redirect to="/" />
              </Route>
              <Route path="/news" exact component={News} />
              <Route path="/news/:newsId" exact component={News} />
              <Route path="/company" exact component={Company} />
              <Route path="/about" exact component={About} />
              <Route path="/terms" exact component={Terms} />
              <Route path="/error" exact component={Error} />
              <Route path="/signin" exact component={AdminSignIn} />
              <Route path="/signout" exact component={AdminSignOut} />
              <Route path={ABP} exact component={Admin} />
              <Route path={ABP + "/uploader"} exact component={AdminImgUp} />
              <Route path={DTBP} component={SupportTool} />
              <Route path="*" component={Error} />
            </Switch>
          </div>
          <Footer />
        </div>
      )}
      <SupportToolBar />
      <GoogleAnalitics />
    </Router>
  );
};

export default App;
