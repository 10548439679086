import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Amplify, { API } from "aws-amplify";

import TopInformation from "./components/TopInformation";
import Teaser from "../_teaser/Teaser";
import Adsense from "./components/Adsense.js"

import { headerUtil } from "../util/headerUtil";
import { LoadingContext } from "../contexts/LoadingContext";
import { listStatusFromPostByDate } from "../graphql/queries";
import { postIF } from "../config/interface";

import Twitter from "./components/Twitter";

import css from "./Home.module.scss";

const fetchItems = 12;
const minItems = 12;

export const Home: React.FC = () => {
  // 認証をIAMに切り替え
  Amplify.configure({
    aws_appsync_authenticationType: "AWS_IAM",
  });

  // title,description
  headerUtil(null,null);

  const [ready, setReady] = useState<boolean>(false);
  const [posts, setPosts] = useState<postIF[]>([]);
  const [nextTokenString, setNextTokenString] = useState<string | null>(null);
  const [moreProcess, setMoreProcess] = useState<boolean>(false);

  const { loading } = useContext(LoadingContext);

  useEffect(() => {
    fetchList();
  }, []);

  const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

  /* リスト取得 */
  async function fetchList(more = false) {
    //console.log("fetchList", more);

    let nextToken = null;
    if (more == true) {
      nextToken = nextTokenString;
    }

    //console.log("nextToken", nextToken);

    const tmp: postIF[] = [];

    for (let i = 0; i < 3; i++) {
      //console.log(i, "nextToken", nextToken);

      const apiData: any = await API.graphql({
        query: listStatusFromPostByDate,
        variables: {
          status: "OPEN",
          sortDirection: "DESC",
          limit: fetchItems,
          nextToken,
        },
      });
      //console.log(apiData);

      const items = apiData.data.listStatusFromPostByDate.items;
      //console.log(items);

      for (const item of items) {
        //console.log(item);

        if (item._deleted == true) {
          continue;
        }

        //console.log(item.id);

        tmp.push(item);
      }

      //console.log(apiData.data.listSamples.nextToken);

      if (apiData.data.listStatusFromPostByDate.nextToken) {
        nextToken = apiData.data.listStatusFromPostByDate.nextToken;
      } else {
        nextToken = null;
      }

      //console.log("tmp.length", tmp.length);

      if (tmp.length >= minItems) {
        break;
      } else if (nextToken == null) {
        break;
      }
    }

    setNextTokenString(nextToken);

    let listTmp: postIF[] = [];

    if (more == true) {
      listTmp = posts;
    }

    listTmp = listTmp.concat(tmp);

    setPosts(listTmp);

    setReady(true);
    setMoreProcess(false);
    loading(false);
  }

  // 追加読み込み
  async function moreFetch() {
    setMoreProcess(true);
    fetchList(true);
  }

  return (
    <>
      {ready && (
        <>
          {posts.length >= 1 ? (
            <>
              <TopInformation />
              <Twitter />

              <div className={css.wrapper}>
                <div className={css.posts_container}>
                  {posts.map((post) => (
                    <Link
                      key={post.id}
                      to={"/recipe/" + post.id}
                      className={css.post_link}
                    >
                      <div className={css.item}>
                        <img
                          src={IMAGE_URL + 'thumb/' + post.image}
                          className={css.post_image}
                        />
                        <div className={css.title}>{post.title}</div>
                      </div>
                    </Link>
                  ))}
                </div>
                {nextTokenString && (
                  <div className={css.more_button_container}>
                    {!moreProcess && (
                      <span className={css.more_button} onClick={moreFetch}>
                        more
                      </span>
                    )}
                  </div>
                )}
                <Adsense />
              </div>
            </>
          ) : (
            <Teaser />
          )}
        </>
      )}
    </>
  );
};

export default Home;
