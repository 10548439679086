import React from "react";
import { Redirect } from "react-router-dom";

import AdminAuth from "./components/AdminAuth";

import { ADMIN_BASE_PATH as ABP } from "../config/config";

export const Admin: React.FC = () => {
  return (
    <AdminAuth>
      <Redirect to={ABP + "/uploader"} />
    </AdminAuth>
  );
};

export default Admin;
