import React, { useState, useEffect, createContext, ReactElement } from 'react';

import css from "./LoadingContext.module.scss";

type ContextType = {
  status: boolean;
  loading: (loading: boolean) => void;
};

const LoadingContext = createContext({} as ContextType);

type PropsType = {
  children: React.ReactNode;
}

const LoadingProvider = ({ children }: PropsType): ReactElement<any, any> => {
  const [status, loading] = useState<boolean>(false);
  const [opacity, setOpacity] = useState<number>(1);

  const [display, setDisplay] = useState<"none" | "block">("none");

  const contextValue = {
    status,
    loading,
  };

  useEffect(() => {
    if (status) {
      window.scrollTo(0, 0);

      setOpacity(0.1);
      setDisplay("block");

      // ローディングが出続けない様に保険
      setTimeout(() => {
        loading(false);
        setDisplay("none");
        setOpacity(1);
      }, 5000);
    } else {
      // 遅延させて消す
      setTimeout(() => {
        loading(false);
        setDisplay("none");
        setOpacity(1);
      }, 200);
    }
  }, [status]);

  return (
    <LoadingContext.Provider value={contextValue}>
      <div className={css.wrapper}>
        <div style={{ opacity: opacity }}>{children}</div>
        <div className={css.container} style={{ display: display }}>
          <div className={css.inner}>
            <i
              className={'fas fa-spinner ' + css.spinner_08s }
            ></i>
          </div>
        </div>
      </div>
    </LoadingContext.Provider>
  );
};

export { LoadingContext, LoadingProvider }
