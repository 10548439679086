import React, { useEffect, useState } from "react";

export interface Props {
  file: File;
  cName: string;
}

const ImagePreview: React.FC<Props> = ({ file, cName }) => {
  const [src, setSrc] = useState("");

  useEffect(() => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      //console.log(e.target.result);
      setSrc(e.target.result);
    };
    reader.readAsDataURL(file);
  }, []);

  //console.log(file);

  return (
    <div className={cName}>
      <img src={src} />
      {file.name}
    </div>
  );
};

export default ImagePreview;
