import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Amplify, { API } from "aws-amplify";

import Error from "./Error";
import Adsense from "./components/Adsense.js";

import { headerUtil } from "../util/headerUtil";
import { LoadingContext } from "../contexts/LoadingContext";
import { getPost, getTag } from "../graphql/queries";
import { postIF, tagIF } from "../config/interface";

import css from "./Recipe.module.scss";

export const Recipe: React.FC = () => {
  // 認証をIAMに切り替え
  Amplify.configure({
    aws_appsync_authenticationType: "AWS_IAM",
  });

  const { postId } = useParams<{ postId: string }>();
  //console.log("postId", postId);

  const [ready, setReady] = useState<boolean>(false);
  const [post, setPost] = useState<postIF | null>(null);
  const [tags, setTags] = useState<tagIF[]>([]);

  const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

  const { loading } = useContext(LoadingContext);

  useEffect(() => {
    fetchData();
  }, []);

  /* データ取得 */
  async function fetchData() {
    //console.log("fetchData");

    loading(true);

    const apiData: any = await API.graphql({
      query: getPost,
      variables: {
        id: postId,
        status: "OPEN",
      },
    });

    const item = apiData.data.getPost;
    //console.log(item);

    setPost(item);

    // タグの処理
    if (item.tags.items.length >= 1) {
      fetchTags(item.tags.items);
    }

    // title,description
    headerUtil(item.title, item.title + " - " + item.description);

    setReady(true);

    loading(false);
  }

  /* タグデータ取得 */
  async function fetchTags(tags: any) {
    //console.log(tags);

    const tmp: tagIF[] = [];

    for (const tag of tags) {
      //console.log(tag.id);

      const apiData: any = await API.graphql({
        query: getTag,
        variables: {
          id: tag.tagID,
          status: "OPEN",
        },
      });

      //console.log(apiData);

      const item = apiData.data.getTag;
      //console.log(item);

      if (item) {
        tmp.push(item);
      }
    }

    setTags(tmp);
  }

  return (
    <div className={css.wrapper}>
      {ready && (
        <>
          {post ? (
            <div className={css.container}>
              <Link to="/" className={css.back_to_home}>
                <i className={"fal fa-arrow-to-left " + css.icon}></i>
                HOME
              </Link>
              <div className={css.contents}>
                <div className={css.title}>{post.title}</div>
                <div className={css.options}>
                  <div>
                    {tags.map((tag) => (
                      <span key={tag.id} className={css.tag}>
                        <i className={"fal fa-tag " + css.tag_icon}></i>#
                        {tag.name}
                      </span>
                    ))}
                  </div>
                  <div className={css.post_data}>投稿日 {post.date}</div>
                </div>

                <div className={css.image_container}>
                  <img
                    src={IMAGE_URL + post.image}
                    className={css.post_image}
                  />
                </div>
                {post.description && (
                  <div className={css.information}>
                    <div className={css.header}>
                      <i className={"fal fa-info-square " + css.icon}></i>
                      このメニューについて
                    </div>
                    <div className={css.text}>{post.description}</div>
                  </div>
                )}
                {post.foodstuff && (
                  <div className={css.information}>
                    <div className={css.header}>
                      <i className={"fal fa-list-ul " + css.icon}></i>材料
                    </div>
                    <div className={css.text}>{post.foodstuff}</div>
                  </div>
                )}
                {post.recipe && (
                  <div className={css.information}>
                    <div className={css.header}>
                      <i className={"fal fa-comment-alt-lines " + css.icon}></i>
                      作り方
                    </div>
                    <div className={css.text}>{post.recipe}</div>
                  </div>
                )}
              </div>

              <Adsense />

              <Link to="/" className={css.back_to_home}>
                <i className={"fal fa-arrow-to-left " + css.icon}></i>
                HOME
              </Link>
            </div>
          ) : (
            <>
              <Error />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Recipe;
