import React from "react";

import { headerUtil } from "../util/headerUtil";

import css from "./Company.module.scss";

export const Company: React.FC = () => {
  // title,description
  headerUtil("運営会社", "運営会社");

  return (
    <div className={css.wrapper}>
      <div className={css.title_container}>
        <img src="/logo512.png" />
      </div>

      <div className={css.content_container}>
        <div className={css.head}>運営会社</div>

        <hr className="hr" />

        <div className={css.inner}>
          <div className={css.item}>
            <div className={css.head}>社名</div>
            <div className={css.contents}>株式会社エーフォーワークス</div>
          </div>
          <div className={css.item}>
            <div className={css.head}>所在地</div>
            <div className={css.contents}>
              〒173-0016
              <br />
              東京都板橋区中板橋24-2
            </div>
          </div>
          <div className={css.item}>
            <div className={css.head}>コーポレートサイト</div>
            <div className={css.contents}>
              <a href="https://www.a4works.co.jp/">
                https://www.a4works.co.jp/
              </a>
            </div>
          </div>
          <div className={css.item}>
            <div className={css.head}>プライバシーポリシー</div>
            <div className={css.contents}>
              <a href="https://www.a4works.co.jp/privacypolicy">
                https://www.a4works.co.jp/privacypolicy
              </a>
            </div>
          </div>
          <div className={css.item}>
            <div className={css.head}>お問合せ</div>
            <div className={css.contents}>
              &#114;&#101;&#109;&#111;&#108;&#117;&#110;&#99;&#104;&#64;&#97;&#52;&#119;&#111;&#114;&#107;&#115;&#46;&#99;&#111;&#46;&#106;&#112;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;
