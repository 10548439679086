import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function GoogleAnalitics() {
  const env = process.env.REACT_APP_ENV;

  const tagId = process.env.REACT_APP_GA_ID;

  const location = useLocation();

  //console.log(env, location, tagId);

  useEffect(() => {
    if (env == "prod" && tagId) {
      setTimeout(() => {
        window.gtag("config", tagId, {
          page_path: location.pathname,
        });
        //console.log("GoogleAnalitics", location.pathname);
      }, 2000);
    }
  }),
    [];

  return <></>;
}
