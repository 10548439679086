import React, { useState, useEffect, useContext } from "react";
import Amplify, { API } from "aws-amplify";

import { listNewsByDate } from "../graphql/queries";

import { headerUtil } from "../util/headerUtil";
import { LoadingContext } from "../contexts/LoadingContext";
import { date } from "../util/dateUtil";
import { newsIF } from "../config/interface";

import css from "./News.module.scss";

export const News: React.FC = () => {
  // 認証をIAMに切り替え
  Amplify.configure({
    aws_appsync_authenticationType: "AWS_IAM",
  });

  const [ready, setReady] = useState<boolean>(false);
  const [newss, setNewss] = useState<newsIF[]>([]);

  const { loading } = useContext(LoadingContext);

  // title,description
  headerUtil("お知らせ", "お知らせ");

  useEffect(() => {
    fetchNews();
  }, []);

  async function fetchNews() {
    loading(true);

    const today = date("Y-m-d H:i");

    const filter = {
      openDate: { le: today },
    };

    //console.log("date", date("Y/m/d H:i:s"));
    //console.log(filter);

    const params = {
      query: listNewsByDate,
      variables: {
        status: "OPEN",
        filter: filter,
        sortDirection: "DESC",
      },
    };

    const responce: any = await API.graphql(params);
    //console.log(responce);

    const newss = responce.data.listNewsByDate.items;
    //console.log(newss);

    setNewss(newss);

    setReady(true);

    loading(false);
  }

  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <i className={"fal fa-comment-alt-lines " + css.icon}></i>お知らせ
      </div>
      {ready && (
        <>
          {newss.map((news) => (
            <div key={news.id} className={css.container}>
              <div>
                <span className={css.title}>
                  {news.title}
                  <span className={css.date}>{date("Y.n.j", news.date)}</span>
                </span>
              </div>

              <div className={css.contents}>{news.contents}</div>
            </div>
          ))}
          {newss.length == 0 && <>お知らせはありません</>}
        </>
      )}
    </div>
  );
};

export default News;
