import React, { useContext } from "react";

import SupporToolHelper from "../_SupporToolHelper";

import { LoadingContext } from "../../contexts/LoadingContext";

import css from "./Sandbox.module.scss";

export const SupportTool: React.FC = () => {

  const { loading } = useContext(LoadingContext);

  const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

  return (
    <SupporToolHelper>
      <div className={css.wrapper}>
        <div className={css.title}>SANDBOX</div>
        <div className={css.button_container}>
          <span
            className={css.button}
            onClick={() => {
              loading(true);
            }}
          >
            LOADING
          </span>
        </div>
        <hr />
        <div className={css.title}>CONFIGs</div>
        <div>IMAGE_URL: {IMAGE_URL}</div>
      </div>
    </SupporToolHelper>
  );
};

export default SupportTool;
