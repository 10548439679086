import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Amplify, { API } from "aws-amplify";

import { listNewsByDate } from "../../graphql/queries";

import { date } from "../../util/dateUtil";

import { newsIF } from "../../config/interface";

import css from "./TopInformation.module.scss";

export const TopInformation: React.FC = () => {
  // 認証をIAMに切り替え
  Amplify.configure({
    aws_appsync_authenticationType: "AWS_IAM",
  });

  const [news, setNews] = useState<newsIF | null>();

  useEffect(() => {
    fetchNews();
  }, []);

  async function fetchNews() {
    const today = date("Y-m-d H:i");

    const filter = {
      openDate: { le: today },
    };

    const params = {
      query: listNewsByDate,
      variables: {
        status: "OPEN",
        sortDirection: "DESC",
        filter: filter,
        limit: 20,
      },
    };

    const responce: any = await API.graphql(params);
    //console.log(responce);

    const newss = responce.data.listNewsByDate.items;
    //console.log(newss);

    if (newss.length >= 1) {
      setNews(newss[0]);
    }
  }

  return (
    <>
      {news && (
        <div className={css.wrapper}>
          <div className={css.container}>
            <div className={css.header}>お知らせ</div>
            <Link to={"/news/" + news.id} className={css.contents}>
              {news.title}
            </Link>
          </div>
          <Link to="/news" className={css.more_button}>
            お知らせ一覧<i className="fal fa-chevron-circle-right ml5"></i>
          </Link>
        </div>
      )}
    </>
  );
};

export default TopInformation;
