import React, { useState, createContext, ReactElement } from "react";

type ContextType = {
  userReady: boolean;
  setReady: (isAdmin: boolean) => void;
  userAuth: boolean;
  setAuth: (auth: boolean) => void;
  userId: string;
  setUserId: (userId: string) => void;
  email: string;
  setEmail: (email: string) => void;
  name: string;
  setName: (name: string) => void;
  isAdmin: boolean;
  setIsAdmin: (isAdmin: boolean) => void;
};

const UserContext = createContext({} as ContextType);

type PropsType = {
  children: React.ReactNode;
};

const UserProvider = ({ children }: PropsType): ReactElement<any, any> => {
  const [userReady, setReady] = useState<boolean>(false);
  const [userAuth, setAuth] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const contextValue = {
    userReady,
    setReady,
    userAuth,
    setAuth,
    userId,
    setUserId,
    email,
    setEmail,
    name,
    setName,
    isAdmin,
    setIsAdmin,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export { UserContext, UserProvider };
