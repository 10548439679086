import React from "react";
import { Link, Switch, Route } from "react-router-dom";

import SupporToolHelper from "./_SupporToolHelper";
import SampleCRUD from "./sampleCRUD/SampleCRUD";
import SANDBOX from "./sandbox/Sandbox";
import SITEMAP from "./sitemap/SitemapSupport";

import { DEVTOOL_BASE_PATH as DTBP } from "../config/config";

import css from "./SupportTool.module.scss";

export const SupportTool: React.FC = () => {
  return (
    <SupporToolHelper>
      <Switch>
        <Route path={DTBP} exact>
          <div className={css.wrapper}>
            <div className={css.title}>Development Support Tool</div>
            <div className={css.item}>
              <Link to={DTBP + "/crud"} className={css.link}>
                SampleCRUD
              </Link>
            </div>
            <div className={css.item}>
              <Link to={DTBP + "/sandbox"} className={css.link}>
                SANDBOX
              </Link>
            </div>
            <div className={css.item}>
              <Link to={DTBP + "/sitemap"} className={css.link}>
                SitemapSupport
              </Link>
            </div>
          </div>
        </Route>
        <Route path="*">
          <div className={css.backlink_wrapper}>
            <Link to={DTBP} className={css.link}>
              <i className="fal fa-arrow-circle-left mr5"></i>Back
            </Link>
          </div>
          <Route path={DTBP + "/crud"} exact component={SampleCRUD} />
          <Route path={DTBP + "/sandbox"} exact component={SANDBOX} />
          <Route path={DTBP + "/sitemap"} exact component={SITEMAP} />
        </Route>
      </Switch>
    </SupporToolHelper>
  );
};

export default SupportTool;
