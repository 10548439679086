import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "../contexts/UserContext";

import css from "./Header.module.scss";

export const Header: React.FC = () => {
  const env = process.env.REACT_APP_ENV;

  const { isAdmin } = useContext(UserContext);

  return (
    <div className={css.wrapper}>
      <div className={css.container}>
        <Link to="/">
          <img
            src="/remomeshi_115x25.png"
            className={css.logo}
            alt="リモメシ"
          />
        </Link>
        <div className={css.right_container}>
          {env != "prod" && <span className={css.mode}>{env}&nbsp;mode</span>}
          {isAdmin && (
            <Link to="/signout" className={css.sign_out}>
              <i className="fal fa-sign-out mr5"></i>サインアウト
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
