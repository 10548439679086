import React, { ReactElement, useContext } from "react";
import Amplify from "aws-amplify";
import { Redirect } from "react-router-dom";

import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
} from "@aws-amplify/ui-react";

import { UserContext } from "../../contexts/UserContext";

const SignIn: React.FC = (): ReactElement<any, any> => {
  const { userAuth, userReady } = useContext(UserContext);

  Amplify.configure({
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  });

  return (
    <>
      {userReady && (
        <>
          {userAuth && <Redirect to="/" />}
          <AmplifyAuthenticator usernameAlias="email">
            <AmplifySignUp
              slot="sign-up"
              usernameAlias="email"
              headerText="メールアドレスとパスワードを登録します"
              submitButtonText="サインアップ"
              formFields={[
                {
                  type: "email",
                  label: "メールアドレス",
                  placeholder: "メールアドレス",
                  required: true,
                },
                {
                  type: "password",
                  label: "パスワード",
                  placeholder: "パスワード",
                  required: true,
                },
              ]}
            />
            <AmplifySignIn
              slot="sign-in"
              headerText="サインイン"
              submitButtonText="サインイン"
              hideSignUp={false} /* true:サインアップ無効 */
              formFields={[
                {
                  type: "email",
                  label: "メールアドレス",
                  placeholder: "メールアドレス",
                  required: true,
                },
                {
                  type: "password",
                  label: "パスワード",
                  placeholder: "パスワード",
                  required: true,
                },
              ]}
            />
          </AmplifyAuthenticator>
        </>
      )}
    </>
  );
};

export default SignIn;
