import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { ADMIN_BASE_PATH as ABP } from "../config/config";
import { DEVTOOL_BASE_PATH as DTBP } from "../config/config";

import { UserContext } from "../contexts/UserContext";

import css from "./SupportToolBar.module.scss";

export const SupportTool: React.FC = () => {
  const env = process.env.REACT_APP_ENV;

  const { userAuth, isAdmin } = useContext(UserContext);

  const cssSelector = () => {
    if (env == 'prod'){
      return css.inner;
    } else {
      return css.inner_dev;
    }
  }

  return (
    <>
      {(env != "prod" || isAdmin) && (
        <div className={css.wrapper}>
          <div className={cssSelector()}>
            <div className={css.header}>Development Support Tool</div>
            <div className={css.item}>env:{env}</div>
            <div className={css.item}>
              <Link to="/signin" className="item">
                Signin
              </Link>
            </div>
            <div className={css.item}>
              <Link to={ABP} className="item">
                AdminTool
              </Link>
            </div>
            <div className={css.item}>
              <Link to={DTBP} className="item">
                DevTool
              </Link>
            </div>
            <div className={css.item}>Auth:{userAuth ? "true" : "false"}</div>
            <div className={css.item}>isAdmin:{isAdmin ? "true" : "false"}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default SupportTool;
