import React, { ReactElement } from 'react'

import css from "./ModalDialog.module.scss";

type PropsType = {
  children: React.ReactNode;
}

const ModalDialog = ({ children }: PropsType): ReactElement<any, any> => {

  return (
    <div className={css.wrapper}>
      <div className={css.containre}>{children}</div>
    </div>
  );

}

export default ModalDialog
