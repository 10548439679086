import React, { ReactElement, useContext } from "react";
import { Redirect } from "react-router-dom";

import { UserContext } from "../contexts/UserContext";

type PropsType = {
  children: React.ReactNode;
};

const SupporToolHelper = ({ children }: PropsType): ReactElement<any, any> => {

  const { isAdmin, userReady } = useContext(UserContext);
  //console.log("isAdmin", isAdmin);

  return (
    <>
      {userReady && isAdmin ? (
        <>{children}</>
      ) : (
        <>
          <Redirect to="/" />
        </>
      )}
    </>
  );
};

export default SupporToolHelper;
