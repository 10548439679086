import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import { I18n } from 'aws-amplify';
import vocabularies from './util/amplifyVocabularies';

import { UserProvider } from './contexts/UserContext';
import { LoadingProvider } from './contexts/LoadingContext';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

import App from './App';

import './index.scss';

I18n.putVocabularies(vocabularies);
I18n.setLanguage('ja');

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <LoadingProvider>
        <App />
      </LoadingProvider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
