import React from "react";

import css from "./Twitter.module.scss";

let isLoadwidgets = false;

const user = "remoteWorkLunch";

export const Twitter: React.FC = () => {
  React.useEffect(() => {
    if (!isLoadwidgets) {
      const s = document.createElement("script");
      s.setAttribute("src", "https://platform.twitter.com/widgets.js");
      document.body.appendChild(s);
      isLoadwidgets = true;
    }
  }, []);

  return (
    <div className={css.wrapper}>
      <div className={css.contents}>
        <a
          className="twitter-timeline"
          data-width="100%"
          data-height="550"
          data-chrome="noheadernofooternoborders"
          href={"https://twitter.com/" + user + "?ref_src=twsrc%5Etfw"}
        >
          A Twitter List by remoteWorkLunch
        </a>
        <div className={css.name}>@remoteWorkLunch</div>
      </div>
    </div>
  );
};

export default Twitter;
