/** 日付フォーマット */

export const date = (style: string, date: string | null = null): string => {
  let d;

  if (date) {
    d = new Date(date);
  } else {
    d = new Date();
  }

  const year = String(d.getFullYear());
  const month = ("0" + (d.getMonth() + 1)).slice(-2);
  const day = ("0" + d.getDate()).slice(-2);
  const hours = ("0" + d.getHours()).slice(-2);
  const minutes = ("0" + d.getMinutes()).slice(-2);
  const seconds = ("0" + d.getSeconds()).slice(-2);

  const month_n = String(d.getMonth() + 1);
  const day_j = String(d.getDate());

  style = style.replace("Y", year);
  style = style.replace("m", month);
  style = style.replace("d", day);

  style = style.replace("n", month_n);
  style = style.replace("j", day_j);

  style = style.replace("H", hours);
  style = style.replace("i", minutes);
  style = style.replace("s", seconds);

  /* 必要に応じて拡張してください */

  return style;
};
