import React from "react";
import { Link } from "react-router-dom";

import css from "./Footer.module.scss";

export const Footer: React.FC = () => {
  return (
    <>
      <div className={css.wrapper}>
        <div className={css.contents}>
          <div className={css.title}>
            <Link to="/" className={css.item}>
              リモメシ Project.
            </Link>
          </div>
          <div className={css.links}>
            <Link to="/news" className={css.item}>
              お知らせ
            </Link>
            <Link to="/about" className={css.item}>
              サイトについて
            </Link>
            <Link to="/company" className={css.item}>
              運営会社
            </Link>
            <Link to="/terms" className={css.item}>
              利用規約
            </Link>
          </div>
        </div>
        <div className={css.copyright}>
          COPYRIGHT (C) A4 WORKS Inc. ALL RIGHT RESERVES.
        </div>
      </div>
    </>
  );
};

export default Footer;
