import React, { useEffect, useContext } from "react";
import { API, Auth } from "aws-amplify";

import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { getUser, listUsers } from "../graphql/queries";
import { createUser as createUserMutation } from "../graphql/mutations";

import { LoadingContext } from "../contexts/LoadingContext";
import { UserContext } from "../contexts/UserContext";

export const UserMgr: React.FC = () => {
  let processLock = false;

  const { setReady, setAuth, setUserId, setEmail, setName, setIsAdmin } =
    useContext(UserContext);

  const { loading } = useContext(LoadingContext);

  useEffect(() => {
    //console.log("useEffect");

    setReady(false);

    Auth.currentAuthenticatedUser()
      .then((authData) => {
        authProsess(authData);
      })
      .catch((err) => {
        //console.warn(err);

        setReady(true);
      });

    onAuthUIStateChange((nextAuthState, authData: any) => {
      //console.log("onAuthUIStateChange", nextAuthState, authData);

      if (nextAuthState === "signedout") {
        // リセット
        setReady(true);
        setAuth(false);
        setUserId("");
        setEmail("");
        setName("");
        setIsAdmin(false);

        // return;
      } else if (nextAuthState === "signedin") {
        if (typeof authData == "undefined") {
          setReady(true);
          //console.log("setReady");
        } else {
          authProsess(authData);
        }
      } else {
        setReady(true);
        //console.log("setReady");
      }
    });
  }, []);

  /** 認証処理 */
  async function authProsess(authData: any) {
    //console.log("authProsess", authData);

    if (processLock) {
      return;
    }

    processLock = true;

    loading(true);

    const userId = authData.username;

    getUserInfo(userId).then(() => {
      //console.log("getUserInfo-fin");

      loading(false);

      setAuth(true);

      setReady(true);
    });
  }

  /** ユーザー情報を取得 取得できない場合は作成 */
  async function getUserInfo(userId: string) {
    //console.log("getUserInfo");

    const result: any = await API.graphql({
      query: getUser,
      variables: { id: userId },
    });
    //console.log(result);

    const user = result.data.getUser;
    //console.log(user);

    // 初回ユーザー
    if (user === null) {
      const filter = {
        isAdmin: { eq: true },
      };

      // 初回ユーザー登録判定
      const adminUsers: any = await API.graphql({
        query: listUsers,
        variables: { filter: filter },
      });

      //console.log(adminUsers.data.listUsers.items);
      //console.log('initializing');

      const newUser = {
        id: userId,
        isAdmin: false,
        status: "ACTIVE",
        sort: "TARGET",
        name: "",
      };

      if (adminUsers.data.listUsers.items.length == 0) {
        //console.log("admin not found. create admin.");

        newUser.isAdmin = true;
      }

      await API.graphql({
        query: createUserMutation,
        variables: { input: newUser },
      });
      //console.log("user created");

      const result: any = await API.graphql({
        query: getUser,
        variables: { id: userId },
      });
      //console.log(result);

      const user = await result.data.getUser;
      //console.log(user);

      // ユーザー情報をセット
      setUserId(user.id);
      setName(user.email);
      setIsAdmin(user.isAdmin);
    } else {
      // ユーザー情報をセット
      setUserId(user.id);
      setName(user.email);
      setIsAdmin(user.isAdmin);
      //console.log(user);
    }

    return true;
  }

  return <></>;
};

export default UserMgr;
