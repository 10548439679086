export const headerUtil = (
  title: string | null,
  description: string | null
) => {
  // title
  const base_title = process.env.REACT_APP_TITLE;
  const base_descriotion = process.env.REACT_APP_DESCRIPTION;

  //sconsole.log(base_title, base_descriotion);

  if (base_title && base_title.length >= 1) {
    if (title) {
      title = title + " - " + base_title;
    } else {
      title = base_title;
    }
  } else {
    title = "";
  }

  if (base_descriotion && base_descriotion.length >= 1) {
    if (description) {
      description = description + " - " + base_descriotion;
      description = DeleteNewLine(description);
    } else {
      description = base_descriotion;
    }
  } else {
    description = "";
  }

  //console.log(title, description);

  // title
  document.title = title;

  // title以外のmeta
  const headData = document.head.children;

  for (let i = 0; i < headData.length; i++) {
    const nameVal = headData[i].getAttribute("name");
    if (nameVal !== null) {
      if (nameVal.indexOf("description") != -1) {
        headData[i].setAttribute("content", description);
      }
      // OGP(twitter)の設定
      // if (nameVal.indexOf("twitter:title") != -1) {
      //   headData[i].setAttribute("content", title);
      // }
      // if (nameVal.indexOf("twitter:description") != -1) {
      //   headData[i].setAttribute("content", description);
      // }
    }
  }
};

export const DeleteNewLine = (myLen: string) => {
  let newLen = "";

  for (let i = 0; i < myLen.length; i++) {
    const text = escape(myLen.substring(i, i + 1));
    if (text != "%0D" && text != "%0A") {
      newLen += myLen.substring(i, i + 1);
    }
  }
  return newLen;
};
