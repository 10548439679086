import React from "react";
import { Link } from "react-router-dom";

import { headerUtil } from "../util/headerUtil";

import css from "./Error.module.scss";

export const Error: React.FC = () => {
  // title,description
  headerUtil("404 Not Found.", "");

  return (
    <div className={css.wrapper}>
      <div className={css.back_icon_button}>
        <Link to="/" className={css.link_direction_none}>
          <i className="fas fa-arrow-circle-left"></i>
        </Link>
      </div>
      <div className={css.message}>
        <div>404 Not Found.</div>
      </div>
    </div>
  );
};

export default Error;
