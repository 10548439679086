import React from "react";

import { headerUtil } from "../util/headerUtil";

import css from "./About.module.scss";

export const About: React.FC = () => {
  // title,description
  headerUtil("このサイトについて", "このサイトについて");

  return (
    <div className={css.wrapper}>
      <div className={css.image}>
        <img src="/logo512.png" />
      </div>

      <div className={css.container}>
        <div className={css.header}>このサイトについて</div>
        <div className={css.inner}>
          <div className={css.item}>
            <div className={css.question_container}>
              <div className={css.head}>Q</div>
              <div className={css.contents}>リモメシ？</div>
            </div>
            <div className={css.answer_container}>
              <div className={css.head}>A</div>
              <div className={css.contents}>
                リモートワークメシ(飯)を紹介するサイトです。
                <br />
                コンセプトは、お昼休みの1時間で作って食べ終わることを目指しています。
                <br />
                食材、調理方法により、朝イチでの仕込みや、前日からの仕込みも行っています。
                <br />
                ※本サービスはベータ版となります
                <br />
                ※今後は利用者さまからの投稿もご紹介できる機能追加を予定しております。
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
