import React, { useState, useEffect, useContext } from "react";
import Amplify, { API } from "aws-amplify";

import awsconfig from "../../aws-exports";
Amplify.configure(awsconfig);

import SupporToolHelper from "../_SupporToolHelper";

import { listSamples } from "../../graphql/queries";
import {
  createSample,
  updateSample,
  deleteSample,
} from "../../graphql/mutations";

import { LoadingContext } from "../../contexts/LoadingContext";

import css from "./SampleCRUD.module.scss";

interface sampleIF {
  id: string;
  cols: string;
  coli: string;
  sort: string;
  _version: string | null;
  _deleted: boolean | null;
}

const defaultFormData: sampleIF = {
  id: "",
  cols: "",
  coli: "",
  sort: "",
  _version: null,
  _deleted: null,
};

const fetchItems = 10;
const minItems = 10;

const RecipeItem: React.FC = () => {
  const [formData, setFormData] = useState<sampleIF>(defaultFormData);
  const [samples, setSamples] = useState<sampleIF[]>([]);
  const [nextTokenString, setNextTokenString] = useState<string | null>(null);
  const [moreProcess, setMoreProcess] = useState<boolean>(false);
  const [formError, setFormError] = useState<boolean>(false);

  const { loading } = useContext(LoadingContext);

  useEffect(() => {
    loading(true);

    fetchList();
  }, []);

  /* リスト取得 */
  async function fetchList(more = false) {
    //console.log("fetchList", more);

    let nextToken = null;
    if (more == true) {
      nextToken = nextTokenString;
    }

    //console.log("nextToken", nextToken);

    const tmp: sampleIF[] = [];

    for (let i = 0; i < 3; i++) {
      //console.log(i, "nextToken", nextToken);

      const apiData: any = await API.graphql({
        query: listSamples,
        variables: {
          limit: fetchItems,
          nextToken,
        },
      });
      //console.log(apiData);

      const items = apiData.data.listSamples.items;
      //console.log(items);

      for (const item of items) {
        //console.log(item);

        if (item._deleted == true) {
          continue;
        }

        //console.log(item.id);

        tmp.push(item);
      }

      //console.log(apiData.data.listSamples.nextToken);

      if (apiData.data.listSamples.nextToken) {
        nextToken = apiData.data.listSamples.nextToken;
      } else {
        nextToken = null;
      }

      //console.log("tmp.length", tmp.length);

      if (tmp.length >= minItems) {
        break;
      } else if (nextToken == null) {
        break;
      }
    }

    setNextTokenString(nextToken);

    let samplesTmp: sampleIF[] = [];

    if (more == true) {
      samplesTmp = samples;
    }

    samplesTmp = samplesTmp.concat(tmp);

    setSamples(samplesTmp);

    setMoreProcess(false);
    loading(false);
  }

  // 追加読み込み
  async function moreFetch() {
    setMoreProcess(true);
    fetchList(true);
  }

  // フォーム切り替え
  const changeItem = (data: sampleIF) => {
    //console.log(data);

    setFormData(data);
  };

  /* 新規作成 */
  async function create() {
    setFormError(false);

    const newData = {
      cols: formData.cols,
      coli: parseInt(formData.coli),
      sort: formData.sort,
    };

    try {
      await API.graphql({
        query: createSample,
        variables: { input: newData },
      });

      setFormData(defaultFormData);
    } catch (e) {
      console.warn(e);
      setFormError(true);
    }

    fetchList();
  }

  /* 情報更新 */
  async function update() {
    //console.log("updateForm", formData);

    const updateData = {
      id: formData.id,
      cols: formData.cols,
      coli: parseInt(formData.coli),
      sort: formData.sort,
      _version: formData._version,
    };

    await API.graphql({
      query: updateSample,
      variables: { input: updateData },
    });

    setFormData(defaultFormData);

    fetchList();
  }

  /* 削除 */
  async function deleteItem(data: any) {
    //console.log("deleteItem", data);

    await API.graphql({
      query: deleteSample,
      variables: {
        input: {
          id: data.id,
          _version: data._version,
        },
      },
    });

    setFormData(defaultFormData);

    fetchList();
  }

  /* キャンセル */
  async function cancel() {
    setFormData(defaultFormData);
  }

  return (
    <SupporToolHelper>
      <div className={css.wrapper}>
        <form className={css.form_container}>
          {formData.id && (
            <>
              <div className={css.button_container}>
                <div>
                  <span className={css.op_button} onClick={cancel}>
                    キャンセル
                  </span>
                  <span
                    className={css.op_button}
                    onClick={() => {
                      deleteItem(formData);
                    }}
                  >
                    削除
                  </span>
                </div>
              </div>
              <div className={css.item}>
                <div className={css.label}>id: {formData.id}</div>
              </div>
            </>
          )}
          <div className={css.item}>
            <div className={css.label}>cols</div>
            <input
              onChange={(e) =>
                setFormData({ ...formData, cols: e.target.value })
              }
              value={formData.cols}
              className={css.input_text}
            />
          </div>
          <div className={css.item}>
            <div className={css.label}>coli</div>
            <input
              onChange={(e) =>
                setFormData({ ...formData, coli: e.target.value })
              }
              value={formData.coli}
              className={css.input_text}
            />
          </div>
          <div className={css.item}>
            <div className={css.label}>公開ステータス</div>
            <select
              className={css.select_box}
              onChange={(e) =>
                setFormData({ ...formData, sort: e.target.value })
              }
              value={formData.sort}
            >
              <option disabled value="">
                選択してください
              </option>
              <option value="TARGET">TARGET</option>
              <option value="SKIP">SKIP</option>
            </select>
          </div>
          {formData.id ? (
            <div className={css.submit_button} onClick={update}>
              更新
            </div>
          ) : (
            <div className={css.submit_button} onClick={create}>
              作成
            </div>
          )}
          {formError && (
            <div className={css.error_info}>入力内容を確認してください</div>
          )}
        </form>

        <hr />
        {samples.map((sample) => (
          <React.Fragment key={sample.id}>
            {sample._deleted == null && (
              <>
                <div
                  className={css.list_item}
                  onClick={() => {
                    changeItem(sample);
                  }}
                >
                  id:{sample.id}
                  <br />
                  cols:{sample.cols}
                  <br />
                  coli:{sample.coli}
                  <br />
                  sort:{sample.sort}
                  <br />
                  ver:{sample._version}
                </div>
              </>
            )}
          </React.Fragment>
        ))}
        {nextTokenString && (
          <div className={css.more_button_container}>
            {!moreProcess && (
              <span className={css.more_button} onClick={moreFetch}>
                more
              </span>
            )}
          </div>
        )}
      </div>
    </SupporToolHelper>
  );
};

export default RecipeItem;
